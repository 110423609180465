import React, { Component } from "react";
import "../styles/sermon.css";
import SermonVideo from "./SermonVideo";

export default class Sermon extends Component {
  state = {
    clickedDate: "",
  };

  setDate = (date) => {
    this.setState({
      clickedDate: date,
    });
  };

  confirmVideoClicked = () => {
    this.setState({
      sermonClicked: false,
      confirmVideoClicked: !this.state.confirmVideoClicked,
    });
  };

  sermonPopupClose = () => {
    this.setState({
      confirmVideoClicked: false,
      confirmAudioClicked: false,
      videoAttachmentExist: false,
      videoAttachmentUrl: "",
    });
  };

  render() {
    const imagePath = (date) =>
      `https://j-grace.s3-us-west-2.amazonaws.com/sermonImages/${date}.png`;

    return (
      <>
        <th
          onClick={() => {
            this.confirmVideoClicked();
            this.setDate("20250209");
          }}
          style={{
            backgroundImage: `url(${imagePath("20250209")})`,
          }}
        ></th>
        <th
          onClick={() => {
            this.confirmVideoClicked();
            this.setDate("20250202");
          }}
          style={{
            backgroundImage: `url(${imagePath("20250202")})`,
          }}
        ></th>
        <th
          onClick={() => {
            this.confirmVideoClicked();
            this.setDate("20250126");
          }}
          style={{
            backgroundImage: `url(${imagePath("20250126")})`,
          }}
        ></th>
        <th
          onClick={() => {
            this.confirmVideoClicked();
            this.setDate("20250119");
          }}
          style={{
            backgroundImage: `url(${imagePath("20250119")})`,
          }}
        ></th>
        <th
          onClick={() => {
            this.confirmVideoClicked();
            this.setDate("20250112");
          }}
          style={{
            backgroundImage: `url(${imagePath("20250112")})`,
          }}
        ></th>
        <th
          onClick={() => {
            this.confirmVideoClicked();
            this.setDate("20250105");
          }}
          style={{
            backgroundImage: `url(${imagePath("20250105")})`,
          }}
        ></th>

        {this.state.confirmVideoClicked && (
          <SermonVideo
            sermonPopupClose={this.sermonPopupClose}
            clickedDate={this.state.clickedDate}
          />
        )}
      </>
    );
  }
}
